import React from "react"
import PropTypes from "prop-types"
import "../styles/hyfennet.sass"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Link } from "gatsby"

const Layout = ({ date, section, title, children, earlierPost, laterPost}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header 
        section={section}
        title={title}
        date={date}
        earlierPost={earlierPost}
        laterPost={laterPost}
        siteTitle={data.site.siteMetadata.title} />
      <main className="container">{children}</main>
      <footer>
        © {new Date().getFullYear()}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
