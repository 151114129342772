import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useRef } from "react"
import RelatedPost from "./RelatedPost";
import useOnClickOutside from "../hooks/useOnClickOutside";

import svgTwitter from '../assets/twitter.svg';
import svgInstagram from '../assets/instagram.svg';
import svgGithub from '../assets/github.svg';
import svgGoodreads from '../assets/goodreads.svg';
import svgRss from '../assets/rss.svg';

const Menu = ({ activeCategory, siteTitle, relatedLinks }) => {
  const ref = useRef(null);

  const [ isOpen, setOpen ] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  const menuClass = `menu__body ${isOpen ? 'menu__body--is-open' : 'menu__body--is-closed'}`

  let menuItems;
  if (process.env.NODE_ENV === `production`) {
    menuItems = [
      { path: 'memex', title: 'Memex' },
      { path: '#bio', title: 'About' },
      { path: '#connect', title: 'Conect' }
    ]
  } else {
    menuItems = [
      { path: 'memex', title: 'Memex' },
      { path: 'projects', title: 'Projects' },
      { path: 'writing', title: 'Writing' },
      { path: 'photography', title: 'Photography' },
      { path: 'travels', title: 'Travels' },
    ]
  }

  return (
    <div className="menu" ref={ref}>
      <button 
        className="menu__hamburger" 
        type="button"
        aria-label="Menu" aria-controls="menu"
        onClick={() => setOpen(!isOpen)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
      </button>
      <nav id="menu" className={menuClass}>
        <ul>
          {menuItems.map(item => {
            const isActive = item.path == activeCategory;
            return (
              <li className='menu__item'>
                <Link to={'/' + item.path} className={isActive ? 'active' : ''}>
                  {item.title}
                </Link>
              </li>
            )}
          )}
        </ul>
        <div class="menu__social">
          <a target="_blank" href="https://twitter.com/hyfen">
            <img width="20" height="20" src={svgTwitter}/>
          </a>
          <a target="_blank" href="https://instagram.com/hyfen">
            <img width="20" height="20" src={svgInstagram} />
          </a>
          <a target="_blank" href="https://github.com/hyfen">
            <img width="20" height="20" src={svgGithub} />
          </a>
          <a target="_blank" href="https://www.goodreads.com/user/show/5069070-andrew-louis">
            <img width="20" height="20" src={svgGoodreads} />
          </a>
          <a target="_blank" href="/rss.xml">
            <img width="20" height="20" src={svgRss} />
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Menu;