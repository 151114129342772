import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react";
import RelatedPost from "./RelatedPost";
import Menu from "./Menu";

const Header = ({ section, title, date, siteTitle, earlierPost, laterPost }) => {
  return (
  <header className="header">
    <nav className="header__nav">
      <h3 className="header__title">
        <Link to="/">
          {siteTitle}
        </Link>
      </h3>
      <div className="header__breadcrumb-mobile">
        <div className="header__breadcrumb__category">
          <Link to={`/${section}`}>
            {section}
          </Link>
        </div>
        <div className="header__post-navigation">
          {earlierPost && (
            <RelatedPost
              title = {earlierPost.title}
              relation = "←"
              path = {earlierPost.path}
            />  
          )}
          {laterPost && (
            <RelatedPost
              title = {laterPost.title}
              relation = "→"
              path = {laterPost.path}
            />  
          )}
        </div>
      </div>
      <div className="header__foo">
        &nbsp;
      </div>
      {/* <div className="related-posts">
        {relatedLinks && relatedLinks.map(link => (
          <RelatedPost
          title = {link.title}
          relation = {link.relation}
          path = {link.path}
          />
          ))}      </div> */}

      <Menu activeCategory={section} />
      <div className="header__post-date">
        {date}
      </div>
      <div className="header__post-title">
        {title}
      </div>
      <div className="header__breadcrumb">
        {earlierPost && (
          <RelatedPost
            title={earlierPost.title}
            relation="← Previous"
            path={earlierPost.path}
          />
        )}
        {laterPost && (
          <RelatedPost
            title={laterPost.title}
            relation="Next →"
            path={laterPost.path}
          />
        )}
      </div>
    </nav>
  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
