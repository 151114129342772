import React from "react"
import { Link } from "gatsby"

export default function RelatedPost({title, relation, path}) {

  return (
    <div className="related-post">
      <Link to={path}>
        <span className="related-post__relation">
          {relation}
        </span>
        <h4 className="related-post__title">
          {title}
        </h4>
      </Link>
    </div>
  )
}
